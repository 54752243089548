import React from 'react';
import './CardProcessos.scss';

export const CardsProcessos = ({Titulo, descricao, Imagem}) => {

    return (
        
        <>
            <div className='processos-card-wrapper'>
                {Imagem}
                <h4>{Titulo}</h4>
                <p>{descricao}</p>
            </div>
        </>
    )
}
