import React from 'react';

import Head from '../Components/seo/Seo';
import Cabecalho from '../Components/header/Header';
import Footer from '../Components/footer/Rodape';
import Processos from '../Components/Processos/logistica';
import BannerManu from '../Components/BannerInfo02/BannerManutencao';
import SuporteMaquinas from '../Components/Maquinas/Suporte';

const ManutencaoPage = () => {

    const InfoPageManutencao = {
        tituloSeo: 'Companhia da Vedação - Manutenção',
        describeSeo: 'especializada em produtos para Vedação Hidráulica e serviços de manutenção industrial em Curitiba',
        TituloProcessos: 'Fale com a gente e cuidamos do resto',
        DescricaoProcessos: 'Trabalhamos analisando cada situação, afinal, problemas diferentes necessitam de soluções diferentes. Sabendo qual o problema e sua origem, podemos oferecer a melhor solução. Lembrando que tudo isso é feito com grande agilidade.',
        TituloBanner: 'Manutenção',
        DescribeBanner: 'Nosso atendimento é focado na solução em manutenção industrial. Oferecemos mão de obra especializada para garantir a melhor procedência na execução e acompanhamento na manutenção, com foco na prevenção de futuros defeitos.',
        
    }
    return (
        <>
            <Head Titulo={`${InfoPageManutencao.tituloSeo}`} Descricao={`${InfoPageManutencao.describeSeo}`}/>
            <Cabecalho LinkName={'Manutencao'}/>
            <BannerManu Titulo={`${InfoPageManutencao.TituloBanner}`} Describe={`${InfoPageManutencao.DescribeBanner}`}/>
            <Processos Titulo={`${InfoPageManutencao.TituloProcessos}`} Describe={`${InfoPageManutencao.DescricaoProcessos}`}/>
            <SuporteMaquinas/>
            <Footer/>
        </>
    )
}

export default ManutencaoPage;