import React from "react";
import { Link } from "gatsby";
import './suporte.scss';

const SuporteMaquinas = () => {
    
    const InfoMaquinas = {
        titulo: 'Precisando de ajuda com sua máquina? Mantenha ela sempre em dia!',
        describe: 'Analisamos o seu problema para entregar a melhor solução, produtos de qualidade e  agilidade e excelência na execução.',
    }
    return (
        <main className="maquinas-wrapper">
            <div className="maquinas-info-wrapper">
                <div className="maquinas-caixa">
                    <h3>{`${InfoMaquinas.titulo}`}</h3>
                    <p>{`${InfoMaquinas.describe}`}</p>
                    <div className="maquinas-botao-wrapper">
                        <Link className="maquinas-botao" to="/Contato" alt="Companhia da Vedação">
                            Entrar em Contato
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default SuporteMaquinas; 