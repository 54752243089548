import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './logistica.scss';
import { CardsProcessos } from './CardProcessos';

const Processos = ({Titulo, Describe}) => {
    
    const infoProcessos = {
        
        subtitulo: 'Nosso processo',
        AltPadrao: 'Companhia da vedação - Vedaç~oes hidraulicas em Curitiba',
        
        TituloCardUm: 'Recebemos seu pedido',
        TituloCardDois: 'Analisamos a melhor solução',
        TituloCardTres: 'Solucionamos o problema',
        TituloCardQuatro: 'Serviço eficiente',

        DescricaoCardUm: 'Você pode vir até nossa loja ou mandar uma imagem e descrição do problema ou peça.',
        DescricaoCardDois: 'Nossos especialistas definem o procedimento e fazem um orçamento dependendo da complexidade do problema.',
        DescricaoCardTres: 'Escolhemos o produto que irá resolver o seu problema ou caso deseje montamos o reparo do produto com defeito.',
        DescricaoCardQuatro: 'Agilidade e capacitação definem um formato de trabalho rápido e eficaz respeitando ao máximo o seu tempo.',
    }

    return (
        <>
            <section className='processos-wrapper'>
                <main className='processos-text-position'>
                    <div className='processos-text-wrapper'>
                        <h5>{`${infoProcessos.subtitulo}`}</h5>
                        <h3>{Titulo}</h3>
                        <p>{Describe}</p>
                    </div>
                </main>
                <main className='processos-icones-wrapper'>
                    <CardsProcessos 
                        Titulo={`${infoProcessos.TituloCardUm}`} 
                        descricao={`${infoProcessos.DescricaoCardUm}`}
                        Imagem= {
                            <StaticImage 
                                className='processos-image-card' 
                                src="../../images/iconeUm.webp" 
                                placeholder="blurred" 
                                loading="lazy" 
                                alt={`${infoProcessos.AltPadrao}`}
                            />
                        } 
                    />
                    <CardsProcessos 
                        Titulo={`${infoProcessos.TituloCardDois}`}
                        descricao={`${infoProcessos.DescricaoCardDois}`}
                        Imagem= {
                            <StaticImage 
                                className='processos-image-card' 
                                src="../../images/iconeDois.png" 
                                placeholder="blurred" 
                                loading="lazy" 
                                alt={`${infoProcessos.AltPadrao}`}
                            />
                        }
                    />
                    <CardsProcessos
                        Titulo={`${infoProcessos.TituloCardTres}`}
                        descricao={`${infoProcessos.DescricaoCardTres}`}
                        Imagem= {
                            <StaticImage 
                                className='processos-image-card' 
                                src="../../images/iconeTres.png" 
                                placeholder="blurred" 
                                loading="lazy" 
                                alt={`${infoProcessos.AltPadrao}`}
                            />
                        }
                    />
                    <CardsProcessos
                        Titulo={`${infoProcessos.TituloCardQuatro}`}
                        descricao={`${infoProcessos.DescricaoCardQuatro}`}
                        Imagem= {
                            <StaticImage 
                                className='processos-image-card' 
                                src="../../images/iconeQuatro.png" 
                                placeholder="blurred" 
                                loading="lazy" 
                                alt={`${infoProcessos.AltPadrao}`}
                            />
                        }
                    />
                </main>
            </section>
        </>
    )
}

export default Processos;